import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL + '/api/';
  }
  GetRobertDatabases(): Observable<any> {
    return this.http.get(this.baseUrl + 'getrobertdatabases');
  }
  GetTablesByDatabase(data): Observable<any> {
    return this.http.post(this.baseUrl + 'gettablesbydatabase', data);
  }
}


