import { Component, OnInit } from '@angular/core';
import { CsvService } from 'src/app/services/csv.service';
import { CombineTables, CsvUpdateService } from 'src/app/services/csv-update.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-csv-parser',
  templateUrl: './csv-parser.component.html',
  styleUrls: ['./csv-parser.component.css']
})
export class CsvParserComponent implements OnInit {
  databasescombine1: any;
  tablesdatacombine1: any;
  databasescombine2: any;
  tablesdatacombine2: any;


  databasessubtract1: any;
  tablesdatasubtract1: any;

  databasessubtract2: any;
  tablesdatasubtract2: any;

  combinedb2 = '';
  combinetbl2 = '';


  subtractdb1 = '';
  subtracttbl1 = '';

  subtractdb2 = '';
  subtracttbl2 = '';

  disabled: boolean = true;
  combineTables: CombineTables[] = [];
  tables: any;
  tablesCombine: any;
  tablesSubtract: any;
  file: any;
  selectedTable: string = "";
  selectedTable1: string = "";
  selectedTable2: string = "";

  selectedSubTable1: string = "";
  selectedSubTable2: string = "";


  selectedSubColumn1: string = "";
  selectedSubColumn2: string = "";

  filename: string = "";
  skiprecs: string = "";
  tables1: any;
  tables2: any;

  colsubtables1: any;
  colsubtables2: any;

  combineTableName: string = "";
  SubtractTableName: string = "";
  id: any;

  combinedb1 = '';
  combinetbl1 = '';
  constructor(private databaseService:DatabaseService,private route: ActivatedRoute, private router: Router,private _csvService: CsvService, private csvUpdateService: CsvUpdateService, private SpinnerService: NgxSpinnerService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }

  ngOnInit(): void {
    this.tables = [];
    this.GetImportedCsvs();
    this.GetRobertDatabases();
  }

  GetRobertDatabases() {
    this.databaseService.GetRobertDatabases()
      .subscribe(
        response => {
          this.databasescombine1 = response;
          this.databasescombine2 = response;
          this.databasessubtract1 = response;
          this.databasessubtract2 = response;
        },
        error => {
        });
  }
  selectDatabaseCombine1(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tablesdatacombine1 = response;
          
        },
        error => {
        });
  }
  selectDatabaseCombine2(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tablesdatacombine2 = response;
        },
        error => {
        });
  }

  selectDatabaseSubtract1(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tablesdatasubtract1 = response;
        },
        error => {
        });
  }
  selectDatabaseSubtract2(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tablesdatasubtract2 = response;
        },
        error => {
        });
  }

  selectTbl(event) {
    //this.selectedTable = event.target.value;
  }

  //public importedData: Array<any> = [];
  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);

  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }

  UploadCsv() {
    if (this.filename == '' || this.filename == null
      || this.filename == undefined
    ) {

    }
    else {
      this.skiprecs = "";
      this.tables = [];
      const data = {
        cols: this._csvService.propertyNames,
        dt: this._csvService.dataStr,
        filename: this.filename
      };
      this.SpinnerService.show();
      this.csvUpdateService.PostCsvData(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            this.filename = "";
            this.GetImportedCsvs();
            if (response.skipfiles != 0 && response.skipfiles != null && response.skipfiles != undefined) {
              this.skiprecs = response.skipfiles;
            }
          },
          error => {

          });
    }
    
  }
  selectTable(event) {
    this.selectedTable = event.target.value;
  }

  selectCombineTable1(event) {
    this.combineTables = [];
    var tablesCombine = this.tables.filter(x => x.tblName != event.target.value);
    this.tablesCombine = tablesCombine;
    this.selectedTable1 = this.combinedb1 + '.dbo.' + this.combinetbl1;

    const data = {
      table: this.selectedTable1,
    };
    this.SpinnerService.show();
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.tables1 = response;

          this.tables1.forEach(element => {
            this.combineTables.push(
              {
                column: element.name,
                selectedValue:"null"
              }
            );
          })
        },
        error => {
          
        });
  }

  selectSubtractTable1(event) {
    //var tablesSubtract = this.tables.filter(x => x.tblName != event.target.value);
    //this.tablesSubtract = this.subtrac;
    this.selectedSubTable1 = this.subtractdb1 + '.dbo.' + this.subtracttbl1;
    const data = {
      table: this.selectedSubTable1,
    };
    this.SpinnerService.show();
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.colsubtables1 = response;

        },
        error => {

        });
  }

  selectSubtractTable2(event) {
    //this.selectedSubTable2 = event.target.value;
    this.selectedSubTable2 = this.subtractdb2 + '.dbo.' + this.subtracttbl2;
    const data = {
      table: this.selectedSubTable2,
    };
    this.SpinnerService.show();
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.colsubtables2 = response;
        },
        error => {

        });
  }
  selectSubtractColumn1(event) {
    this.selectedSubColumn1 = event.target.value;
  }
  selectSubtractColumn2(event) {
    this.selectedSubColumn2 = event.target.value;
  }

  selectColumn(event, column) {
    this.combineTables.forEach(element => {
      if (element.column == column) {
        element.selectedValue = event.target.value
      }
    })
  }
  selectCombineTable2(event) {
    this.selectedTable2 = this.combinedb2 + '.dbo.' + this.combinetbl2;

    const data = {
      table: this.selectedTable2,
    };
    this.SpinnerService.show();
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.tables2 = response;
        },
        error => {

        });
  }
  CombineTables() {
    if (this.selectedTable1 == '' || this.selectedTable1 == null
      || this.selectedTable1 == undefined
    ) {

    }
    else if (this.selectedTable2 == '' || this.selectedTable2 == null
      || this.selectedTable2 == undefined
    ) {

    }
    else if (this.combineTableName == '' || this.combineTableName == null
      || this.combineTableName == undefined
    ) {

    }
    else {
      const data = {
        table1: this.selectedTable1,
        table2: this.selectedTable2,
        columns: this.combineTables,
        tablename: this.combineTableName
      };
      this.SpinnerService.show();
      this.csvUpdateService.CombineTables(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            this.GetImportedCsvs();
          },
          error => {
            this.SpinnerService.hide();
          });
    }
    
  }
  SubtractTables() {
    
    if (this.selectedSubTable1 == '' || this.selectedSubTable1 == null
      || this.selectedSubTable1 == undefined
    ) {

    }
    else if (this.selectedSubTable2 == '' || this.selectedSubTable2 == null
      || this.selectedSubTable2 == undefined
    ) {

    }
    else if (this.selectedSubColumn1 == '' || this.selectedSubColumn1 == null
      || this.selectedSubColumn1 == undefined
    ) {

    }
    else if (this.selectedSubColumn2 == '' || this.selectedSubColumn2 == null
      || this.selectedSubColumn2 == undefined
    ) {

    }
    else if (this.SubtractTableName == '' || this.SubtractTableName == null
      || this.SubtractTableName == undefined
    ) {

    }
    else {
      const data = {
        table1: this.selectedSubTable1,
        table2: this.selectedSubTable2,
        table1EmailCol: this.selectedSubColumn1,
        table2EmailCol: this.selectedSubColumn2,
        tablename: this.SubtractTableName
      };
      this.SpinnerService.show();
      this.csvUpdateService.SubtractTables(data)
        .subscribe(
          response => {
            //console.log(response);
            this.SpinnerService.hide();
            this.GetImportedCsvs();
          },
          error => {
            this.SpinnerService.hide();
          });
    }
    
  }
  DeleteDuplicate() {
    if (this.selectedTable == '' || this.selectedTable == null
      || this.selectedTable == undefined
    ) {

    }
    else {
      const data = {
        table: this.selectedTable,
      };
      this.SpinnerService.show();
      this.csvUpdateService.DeleteDuplicate(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
          },
          error => {
            this.SpinnerService.hide();
          });
    }
    
  }
  GetImportedCsvs() {
    this.SpinnerService.show();
    this.csvUpdateService.GetImportedCsvs()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.tables = response;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  ExportCsv() {
    if (this.selectedTable == '' || this.selectedTable == null
      || this.selectedTable == undefined
    ) {

    }
    else {
      const data = {
        table: this.selectedTable,
      };
      var name = this.selectedTable + '.csv';
      this.SpinnerService.show();
      this.csvUpdateService.ExportCsv(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            const url = window.URL.createObjectURL(new Blob(response, { type: 'text/csv;encoding:utf-8' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', name)
            document.body.appendChild(link)
            link.click()
          },
          error => {
            this.SpinnerService.hide();
          });
    }
    
  }
}
