import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


    public form: FormGroup;
    submitted = false;

    constructor(public globals: ThemeOptions, private SpinnerService: NgxSpinnerService, private userservice: UserService, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private toastr: ToastrService) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

    }

    // convenience getter for easy access to form fields

    get f() { return this.form.controls; }

    onSubmit() {
        //debugger;
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }
        else {
            const logindata = {
                email: this.form.controls.email.value,
                password: this.form.controls.password.value
            }
            this.SpinnerService.show();
            this.userservice.loginuser(logindata).subscribe((data: any) => {
                this.SpinnerService.hide();
                // debugger;

                if (data.length > 0) {
                    localStorage.setItem('token', data[0].uid);
                  this.router.navigate(['/csv-parser']);

                } else {
                    this.router.navigate(['/sign-in']);
                    this.toastr.error('Invalid login, please try with valid credentials.', 'Error!');
                }
            },error =>
            {
                this.SpinnerService.hide();
                this.toastr.error(error.error.message, 'Error!');
            });
        }
    }
}
