import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// import {SearchComponent} from './DemoPages/consultant/search/search.component';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';



import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';
import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';
import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';
import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
import { DashboardComponent } from './DemoPages/Dashboards/dashboard/dashboard.component';
import { LoginActivate } from './DemoPages/UserPages/login/login-activate.model';
import { AllStatsComponent } from './DemoPages/all-stats/all-stats.component';
import { DomainCsvFilesComponent } from './DemoPages/domain-csv-files/domain-csv-files.component';
import { ApiTestComponent } from './DemoPages/api-test/api-test.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CsvParserComponent } from './DemoPages/csv-parser/csv-parser.component';
import { SftpDownloaderComponent } from './DemoPages/sftp-downloader/sftp-downloader.component';
import { UpdateAccountComponent } from './DemoPages/update-account/update-account.component';
import { TableColumnsComponent } from './DemoPages/table-columns/table-columns.component';
import { EmailSortingStatsComponent } from './DemoPages/email-sorting-stats/email-sorting-stats.component';
import { SearchEmailComponent } from './DemoPages/search-email/search-email.component';
import { RgrsuppressionsComponent } from './DemoPages/rgr-suppressions/rgr-suppressions.component';
import { SuppressionsStatsComponent } from './DemoPages/suppressions-stats/suppressions-stats.component';
import { OngageExportComponent } from './DemoPages/ongage-export/ongage-export.component';
import { EmailSenderLogComponent } from './DemoPages/email-sender-log/email-sender-log.component';
const routes: Routes = [
  { path: '', component: UnauthorizedComponent, data: { extraParameter: 'dashboardsMenu' } },
  { path: 'unauthorized', component: UnauthorizedComponent, data: { extraParameter: 'dashboardsMenu' } },
  { path: 'import-csv/:id', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' },  },
  { path: 'all-stats/:id', component: AllStatsComponent, data: { extraParameter: 'dashboardsMenu' },  },

  { path: 'domain-csv-files/:id', component: DomainCsvFilesComponent, data: { extraParameter: 'dashboardsMenu' },  },
  { path: 'rgr-suppressions/:id', component: RgrsuppressionsComponent, data: { extraParameter: 'dashboardsMenu' } },
  { path: 'api-test/:id', component: ApiTestComponent, data: { extraParameter: 'dashboardsMenu' } },
  { path: 'table-columns/:id', component: TableColumnsComponent, data: { extraParameter: 'dashboardsMenu' } },

  { path: 'csv-parser/:id', component: CsvParserComponent },
  { path: 'sftp-downloader/:id', component: SftpDownloaderComponent },
  { path: 'update-account/:id', component: UpdateAccountComponent },
  { path: 'email-sorting-stats/:id', component: EmailSortingStatsComponent },
  { path: 'suppressions-stats/:id', component: SuppressionsStatsComponent },
  { path: 'search-email/:id', component: SearchEmailComponent },
  { path: 'ongage-export/:id', component: OngageExportComponent },
  { path: 'email-sender-log/:id', component: EmailSenderLogComponent },
  { path: '**', redirectTo: '' },


  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
