import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllstatService } from 'src/app/services/allstat.service';
import { Global } from '../common/global';

@Component({
  selector: 'app-all-stats',
  templateUrl: './all-stats.component.html',
  styleUrls: ['./all-stats.component.css']
})
export class AllStatsComponent implements OnInit {
  id: any;
  heading = 'All Stats';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  stats: any;
  @ViewChild('statsTable') statsTable: ElementRef;
  constructor(private router: Router,private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, private allstatService: AllstatService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }
  ngOnInit(): void {
    this.GetAllStats();
  }
  GetAllStats() {
    this.SpinnerService.show();
    this.allstatService.getAllStats()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          console.log(response);
          this.stats = response;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  copyToClipboard(text) {
    navigator['clipboard'].writeText(text).then().catch(e => console.error(e));
  }
  Copy() {
    var str = '';
    var hearder = this.statsTable.nativeElement.tHead.innerText.split('\t');
    var str = '';
    //var hdr = '                                                                                                                                                               ';
    //for (var i = 0; i < hearder.length; i++) {
    //  var c = 30;
    //  if (i == hearder.length - 1) {
    //    c = 60;
    //  }
    //  var position = i * c;
    //  var b = hearder[i];
    //  var a = hdr;
    //  var output = [a.slice(0, position), b, a.slice(position)].join('');
    //  hdr = output;
    //}
    //str = hdr;
    //str = str.replace(/,(\s+)?$/, '');
    //str = str + '\r\n';

    var tr = this.statsTable.nativeElement.children[1].children;
    for (var i = 0; i < tr.length; i++) {
      var row = tr[i].innerText;
      if (i > 10) {
        debugger;
      }
      var rowSp = row.split('\t');
      var line = '                                                                                                                                                               ';
      var style = '';
      for (var p = 1; p < rowSp.length; p++) {
        var col = rowSp[p].replace(/(?:\r\n|\r|\n)/g, '');
        var c = 0;
        if (p == rowSp.length - 1) {
          c = 40;
        }
        var position = p * c;
        var b = col;
        var a = line;
        var output = [a.slice(0, position), b, a.slice(position)].join('');
        line = output;

        if(p == 2) {
          if (col == '0') {
            style = '<span style="color: red" >';
          }
        }
      }
      if (style != '') {
        line = style+line+'</span>'
      }
      line = line.replace(/,(\s+)?$/, '');
      str = str + line;
      str = str + ' <br>';
      if (i == 2 || i == 5 || i == 8 || i == 11 || i == 14 || i == 17 || i == 20 || i == 23) {
        str = str + ' <br>';
      }
    }

    const el = document.createElement('div');
    el.innerHTML = str;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const sel = getSelection();
    sel.setBaseAndExtent(el, 0, el, el.childNodes.length);
    document.execCommand('copy');

    //this.copyToClipboard(str);
  }

}
