<div class="row justify-content-center margin-left4">
  <div class="col-lg-12 col-xl-12">
    <div class="page-header">
      <h1>Update Account</h1>
      <div class="d-flex align-items-center">

      </div>
    </div>

    <div class="containertab">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Name</label>
          <input type="text"
                 formControlName="name"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
            <div *ngIf="f.name.errors.minlength">
              Name must be at least 2 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Host</label>
          <input type="text"
                 formControlName="host"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.host.errors }" />
          <div *ngIf="submitted && f.host.errors" class="invalid-feedback">
            <div *ngIf="f.host.errors.required">Host is required</div>
            <div *ngIf="f.host.errors.minlength">
              Host must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Username</label>
          <input type="text"
                 formControlName="username"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
            <div *ngIf="f.username.errors.minlength">
              Username must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input type="text"
                 formControlName="password"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">
              Password must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>keep Alive Interval</label>
          <input type="number"
                 formControlName="keepaliveInterval"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.keepaliveInterval.errors }" />
          <div *ngIf="submitted && f.keepaliveInterval.errors" class="invalid-feedback">
            <div *ngIf="f.keepaliveInterval.errors.required">keep alive interval is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>keep Alive Count Max</label>
          <input type="number"
                 formControlName="keepaliveCountMax"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.keepaliveCountMax.errors }" />
          <div *ngIf="submitted && f.keepaliveCountMax.errors" class="invalid-feedback">
            <div *ngIf="f.keepaliveCountMax.errors.required">keep alive count max is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>Port</label>
          <input type="number"
                 formControlName="port"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.port.errors }" />
          <div *ngIf="submitted && f.port.errors" class="invalid-feedback">
            <div *ngIf="f.port.errors.required">Port is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>Type</label>
          <select formControlName="type" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
            <option value=""></option>
            <option value="ftp">Ftp</option>
            <option value="sftp">Sftp</option>
          </select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Type is required</div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary">Submit</button>
          <button type="button"
                  (click)="onReset()"
                  class="btn btn-warning float-right">
            Reset
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
