<div class="row justify-content-center margin-left4">
  <div class="col-lg-12 col-xl-12">
    <div class="page-header">
      <h1>SFTP Downloader</h1>
      <div class="d-flex align-items-center">

      </div>
    </div>

    <div class="containertab">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Name</label>
          <input type="text"
                 formControlName="name"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
            <div *ngIf="f.name.errors.minlength">
              Name must be at least 2 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Host</label>
          <input type="text"
                 formControlName="host"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.host.errors }" />
          <div *ngIf="submitted && f.host.errors" class="invalid-feedback">
            <div *ngIf="f.host.errors.required">Host is required</div>
            <div *ngIf="f.host.errors.minlength">
              Host must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Username</label>
          <input type="text"
                 formControlName="username"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
            <div *ngIf="f.username.errors.minlength">
              Username must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input type="text"
                 formControlName="password"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">
              Password must be at least 2 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>keep Alive Interval</label>
          <input type="number"
                 formControlName="keepaliveInterval"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.keepaliveInterval.errors }" />
          <div *ngIf="submitted && f.keepaliveInterval.errors" class="invalid-feedback">
            <div *ngIf="f.keepaliveInterval.errors.required">keep alive interval is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>keep Alive Count Max</label>
          <input type="number"
                 formControlName="keepaliveCountMax"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.keepaliveCountMax.errors }" />
          <div *ngIf="submitted && f.keepaliveCountMax.errors" class="invalid-feedback">
            <div *ngIf="f.keepaliveCountMax.errors.required">keep alive count max is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>Port</label>
          <input type="number"
                 formControlName="port"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.port.errors }" />
          <div *ngIf="submitted && f.port.errors" class="invalid-feedback">
            <div *ngIf="f.port.errors.required">Port is required</div>
          </div>
        </div>

        <div class="form-group">
          <label>Type</label>
          <select formControlName="type" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
            <option value=""></option>
            <option value="ftp">Ftp</option>
            <option value="sftp">Sftp</option>
          </select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Type is required</div>
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary">Submit</button>
          <button type="button"
                  (click)="onReset()"
                  class="btn btn-warning float-right">
            Reset
          </button>
        </div>
      </form>

      <div class="form-group">
        <label>Select Account</label>
        <select class="form-control" (change)="changeAccount($event)">
          <option value=""></option>
          <option *ngFor="let a of accounts" [value]="a.id">
            {{a.name}}
          </option>
        </select>
        <button type="button" class="btn btn-primary margin-top5" (click)="updateAccount()">Update Account</button>
      </div>


      <div class="content-list">
        <div class="row content-list-head">
          <div class="col-auto">
            <h3>Files</h3>
          </div>
        </div>

        <button *ngIf="path != ''" type="button"
                (click)="Back()"
                class="btn btn-warning btn-sm">
          Back
        </button>
        <br />
        <span *ngIf="accountId != ''">/{{path}}</span>

        <div class="content-list-body row">
          <div class="col">

            <ul class="list-group list-group-activity dropzone-previews flex-column-reverse filter-list-1645442056585">
              <li class="list-group-item" data-t="null" data-i="null" data-l="null"
                  data-e="null" *ngFor="let f of files; index as i;">
                <div class="media align-items-center" *ngIf="f.type == '-'">
                  <ul class="avatars">
                    <li>
                      <div class="avatar bg-primary">
                        <svg _ngcontent-pqj-c154=""
                             xmlns="http://www.w3.org/2000/svg" height="24"
                             viewBox="0 0 24 24" width="24" class="filter-white">
                          <path _ngcontent-pqj-c154="" d="M0 0h24v24H0z"
                                fill="none"></path>
                          <path _ngcontent-pqj-c154=""
                                d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z">
                          </path>
                        </svg>
                      </div>
                    </li>
                  </ul>
                  <div class="media-body d-flex justify-content-between align-items-center">
                    <div>
                      <a [routerLink]="" (click)="DownloadFile(f.name)"
                         data-filter-by="text"
                         class="A-filter-by-text">{{f.name}} ({{GetSize(f.size)}})</a>
                      <br>
                      <span class="text-small SPAN-filter-by-text"
                            data-filter-by="text">{{GetDate(f.modifyTime) | date:'medium' }}</span>
                    </div>
                  </div>
                </div>

                <div class="media align-items-center" *ngIf="f.type == 'd'">
                  <ul class="avatars">
                    <li>
                      <div class="avatar bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" id="mainIconPathAttribute" fill="orange"></path> </svg>
                      </div>
                    </li>
                  </ul>
                  <div class="media-body d-flex justify-content-between align-items-center">
                    <div>
                      <a [routerLink]="" (click)="OpenFolder(f.name)"
                         data-filter-by="text"
                         class="A-filter-by-text">{{f.name}}</a>
                      <br>
                      <span class="text-small SPAN-filter-by-text"
                            data-filter-by="text">{{GetSize(f.size)}}</span>

                      <br>
                      <span class="text-small SPAN-filter-by-text"
                            data-filter-by="text">{{GetDate(f.modifyTime) | date:'medium' }}</span>
                    </div>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
