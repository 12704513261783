<div>

  <div class="form-group">
    <label for="emailsearch">Export Id</label>
    <input type="text" class="form-control width20"  [(ngModel)]="exportid" (keydown.enter)='Search($event)' id="emailsearch" placeholder="Enter Export Id" >
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p *ngIf="total > 0" style="color: white">
    Total : {{total}} <span class="paddingleft26" *ngIf="processed > 0">Processed : {{processed}}</span>
    <span class="paddingleft26" *ngIf="success > 0">Success : {{success}}</span>
    <span class="paddingleft26" *ngIf="failed > 0">Failed : {{failed}}</span>
  </p>
</ngx-spinner>
