import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'ArchitectUI - Angular 7 Bootstrap 4 & Material Design Admin Dashboard Template';
  websiteTitle: string ;
  websitefavicon: string ;

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  constructor(private titleService: Title) { }
  ngOnInit() {
    this.titleService.setTitle('RGR Import ');
}
}
