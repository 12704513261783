<div>

  <div class="form-group">
    <label for="emailsearch">Search Email</label>
    <input type="text" class="form-control width20"  [(ngModel)]="email" (keydown.enter)='Search($event)' id="emailsearch" placeholder="Enter Email" >
  </div>

  <div  *ngIf="founddata.length > 0">
    <h1>Email found in:</h1>
    <br />
    <table class="res-table">
      <thead>
        <tr>
          <th scope="col">DatabaseName</th>
          <th scope="col">TableName</th>
          <th scope="col">ColumnName</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of founddata; index as i">
          <td data-label="DatabaseName">
            {{d.DatabaseName }}
          </td>
          <td data-label="TableName">
            {{d.TableName }}
          </td>
          <td data-label="ColumnName">
            {{d.ColumnName }}
          </td>
          <td data-label="Actions">
            <button class="btn btn-sm btn-danger" (click)="DeleteRecordFromDb(d.DatabaseName,d.TableName,d.ColumnName)">Delete</button>
          </td>
        </tr>

      </tbody>
    </table>
  </div>

  <div  *ngIf="notfounddata.length > 0">
    <h1>Email not found in:</h1>
    <br />
    <table class="res-table">
      <thead>
        <tr>
          <th scope="col">DatabaseName</th>
          <th scope="col">TableName</th>
          <th scope="col">ColumnName</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of notfounddata; index as i">
          <td data-label="DatabaseName">
            {{d.DatabaseName }}
          </td>
          <td data-label="TableName">
            {{d.TableName }}
          </td>
          <td data-label="ColumnName">
            {{d.ColumnName }}
          </td>
        </tr>

      </tbody>
    </table>
  </div>

</div>
<!--<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>-->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"><p style="color: white"> {{seconds}} </p></ngx-spinner>
