<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>
    <div class="row">
      <div class="col-md-3 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Select Database</label>
        <select (change)="selectDatabase($event)" [(ngModel)]="db" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of databases" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
      <div class="col-md-3 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Select Table</label>
        <select (change)="selectTable($event)" [(ngModel)]="tbl" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of tables" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
      <div class="col-md-2 floatleft position-relative form-group margintop8">
        <label for="exampleFile">Domains</label>
        <input placeholder="" [(ngModel)]="domains" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
      </div>
      <div class="col-md-3 floatleft position-relative form-group margintop8">
        <label for="exampleFile"></label>
        <button type="button" class="mt-1 btn btn-primary btnDomain" (click)="CreateDomainFiles()">Create Domain Files</button>
      </div>
    </div>
    <h6>C:\Users\Administrator\Desktop\Source\AngularProjs\Utilities\API\Csv</h6>
    <div class="row">
      <div class="domainsDiv">
        <table id="tblColumns">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Domain</th>
              <th scope="col">Counter</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of checkdomains; index as i;">
              <td data-label="No">{{i+1}}</td>
              <td data-label="Domain">{{t.domain}}</td>
              <td data-label="Counter">{{t.counter}}</td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>

  </div>
</div>

<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>


