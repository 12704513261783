import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { CombineTables, CsvService, SelectColumns } from 'src/app/services/csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../../services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-rgr-suppressions',
  templateUrl: './rgr-suppressions.component.html',
  styleUrls: ['./rgr-suppressions.component.sass']
})
export class RgrsuppressionsComponent implements OnInit {
  spinner = 1;
  domainslength = 0;
  day :any;
  totalRec = 0;
  amountofdata = 10;
  alldomain = '';
  alldomains: any;
  modaltype = '';
  checkdomains: any;

  closeResult = '';
  showingrecords = '';
  heading = 'Domains Filter Custom';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  


  selectedColumns: SelectColumns[] = [];

  website = '';
  from = '';
  to = '';
  totalrec = 0;
  totalrecTxt = '';
  records = [];
  exportedCount = 0;
  nonexportedCount = 0;
  totaldomainsdata: any;
  totaldomains = 0;
  countrec = 0;


  total = 0;
  success = 0;
  processed = 0;
  failed = 0;
  failedtxt = '';
  bshowfailed = false;
  statid: any;
  mySub: Subscription;

  
  id: any;
  datedfrom = '';
  datedto = '';
  senttoRGR = false;
  master = 'admin_febdata.dbo.RGRSuppressions';
  cols: string[] = [];
  file: any;
  filename = '';
  mappedData: CombineTables[] = [];
  maintable_columns: any;

  constructor(public _csvService: CsvService, public router: Router, private route: ActivatedRoute,public utilityService: UtilityService, private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public csvUpdateService: CsvUpdateService, private toastr: ToastrService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }

  ngOnInit(): void {
    var m_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun', 'Jul',
      'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date();
    var n = m_names[d.getMonth()];
    var year = new Date().getFullYear();

    this.GetRecords();
  }
  GetTableColumns() {
    const data = {
      table: 'admin_febdata.dbo.RGRSuppressions',
    };
    this.csvUpdateService.GetTableColumns(data)
      .subscribe(
        response => {
          this.mappedData = [];
          this.maintable_columns = response;
          this.maintable_columns.forEach((row) => {
            var selectedValue = '';
            this.mappedData.push(
              {
                column: row.name,
                selectedValue: selectedValue
              }
            );

          })
        },
        error => {
        });
  }
  
  
  
  LoadModal(content: any, type: string) {
    this.modaltype = type;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: "myCustomModalClass",
      size: 'md'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);
    this.cols = this._csvService.propertyNames;
    this.GetTableColumns();
  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }
  GetRecords() {
    const data = {
      dateFrom: this.datedfrom,
      dateTo: this.datedto,
      senttoRGR: this.senttoRGR
    };
    this.spinner = 1;
    this.SpinnerService.show();
    this.csvUpdateService.Rgrsuppressions(data)
      .subscribe(
        items => {
          this.SpinnerService.hide();
          this.records = items.recordsets[0];
          this.totalrec = items.recordsets[0].length;
          if (this.totalrec > 10) {
            this.showingrecords = "showing first 10 records as sample";
          }
          else {
            this.showingrecords = "showing all " + this.totalrec + " records";
          }
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }
  ExportCsv() {
    const data = {
      dateFrom: this.datedfrom,
      dateTo: this.datedto,
      senttoRGR: this.senttoRGR
    };
    this.spinner = 1;
    this.SpinnerService.show();
    this.csvUpdateService.RgrsuppressionsExportedData(data)
      .subscribe(
        items => {
          this.SpinnerService.hide();
          var items = items.recordsets[0];
          this.SpinnerService.hide();
          let csv

          var l = items.length;
          if (l > 0) {
            l = 1;
          }
          // Loop the array of objects
          for (let row = 0; row < 1; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

              // Loop each property of the object
              for (let key in items[row]) {
                // This is to not add a comma at the last cell
                // The '\r\n' adds a new line
                csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }
              csv = csv.replace("undefined", '');
            }
            keysCounter = 0
          }
          for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0


            for (let key in items[row]) {
              csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
              keysCounter++
            }

            keysCounter = 0
          }
          var link = document.createElement('a');
          link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
          link.target = '_blank';
          link.download = 'export.csv';
          link.click();
          this.ExportRgrsuppressions();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }

  ExportRgrsuppressions() {
    const data = {
      dateFrom: this.datedfrom,
      dateTo: this.datedto,
      senttoRGR: this.senttoRGR
    };
    this.spinner = 1;
    this.csvUpdateService.ExportRgrsuppressions(data)
      .subscribe(
        items => {
          this.GetRecords();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }

  UploadCsv() {
    if (this.filename == '' || this.filename == null
      || this.filename == undefined 
    ) {

    }
    else {
      var mappedData = this.mappedData;
      this._csvService.dataStr = this._csvService.dataStr.filter(item => item != '' && item != '\r');
      this._csvService.propertyNames = this._csvService.propertyNames.filter(item => item != 'id' && item != 'Vendor' && item != '' && item != '\r');
      var length = this._csvService.dataStr.length;
      this.total = length;
      var v = 'suppressions_trigger'.replace(" ", "_");
      const data = {
        cols: this._csvService.propertyNames,
        filename: "tbl_" + v,
        total: length,
        vendor: 'suppressions_trigger',
        mappedData: mappedData,
        master: this.master,
        website:this.website
      };

      this.csvUpdateService.CreateTable(data)
        .subscribe(
          response => {
            this.spinner = 2;
            this.SpinnerService.show();
            if (response.res == '1') {
              this.statid = response.statid;
              this.mySub = interval(1000).subscribe((func => {
                this.getstats();
              }))

              this.PostCsvData(response.table, response.statid);
            }
          },
          error => {
          });
    }


  }
  getstats() {
    const data = {
      id: this.statid
    };
    this.csvUpdateService.getstats(data)
      .subscribe(
        response => {

          this.processed = response[0].process;
          this.success = response[0].success;
          this.failed = response[0].failed;
          if (this.processed > 0 && this.processed == this.total) {
            this.SpinnerService.hide();
            this.stopInterval();
          }
        },
        error => {
          console.log(error);
        });
  }
  stopInterval() {
    this.mySub.unsubscribe();
    this.GetRecords();
  }

  PostCsvData(tbl, statid) {
    const userid = localStorage.getItem("token");
    const data = {
      recs: this._csvService.dataStr,
      totalcols: this._csvService.propertyNames.length,
      tbl: tbl,
      statid: statid,
      addresscol: 0,
      vendor: 'suppressions_trigger',
      userid: userid,
      filename: this.filename
    };
    this.csvUpdateService.InsertCsvData(data)
      .subscribe(
        response => {
        },
        error => {
          console.log(error);
        });
  }

}
