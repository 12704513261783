


<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>


    <div class="row margin-top36">
      <h3>Import Data</h3><br />

      <div class="col-md-3 floatleft position-relative form-group hidden">
        <label for="exampleEmail">Website</label>
        <input name="website"
               id="exampleEmail" placeholder="" [(ngModel)]="website" [ngModelOptions]="{standalone: true}" type="text" class="form-control">
      </div>

      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">CSV File</label>
        <input #fileUploadSimple [accept]="'.csv'" type="file" class="form-control-file"
               (change)="importDataFromCSV($event)">
      </div>


      <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
               [value]="this.mappedData" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
               responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
               styleClass="p-datatable-offlineconsultants" [paginator]="true"
               [ngClass]="_csvService.propertyNames.length > 0 ? 'heightauto' : 'height0'"
               currentPageReportTemplate="{first} to {last} of {totalRecords}">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn>Master</th>
            <th pFrozenColumn>Mapped Column</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Items>
          <tr *ngIf="Items.column != 'senttoRGR' && Items.column != 'website'">
            <td>{{Items.column}}</td>
            <td>
              <select [(ngModel)]="Items.selectedValue" [ngModelOptions]="{standalone: true}">
                <option value=""></option>
                <option *ngFor="let p of _csvService.propertyNames" [value]="p">
                  {{p}}
                </option>
              </select>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No record found.</td>
          </tr>
        </ng-template>
      </p-table>

      <br>
      <button type="button" class="mt-1 btn btn-primary" (click)="UploadCsv()">Upload</button>
    </div>
    <div class="row margin-top36">
      <h3>Export & Filter Data</h3><br />
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">SenttoRGR</label>
        <input name="senttoRGR"
               id="exampleEmail" placeholder="" [(ngModel)]="senttoRGR" [ngModelOptions]="{standalone: true}" type="checkbox" class="form-control">
      </div>
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">From</label>
        <input name="vendor"
               id="exampleEmail" placeholder="" [(ngModel)]="datedfrom" [ngModelOptions]="{standalone: true}" type="date" class="form-control">
      </div>
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">To</label>
        <input name="vendor"
               id="exampleEmail" placeholder="" [(ngModel)]="datedto" [ngModelOptions]="{standalone: true}" type="date" class="form-control">

      </div>


    </div>
      <button type="button" class="mt-1 btn btn-primary" (click)="GetRecords()">Filter</button>

      <button type="button" class="mt-1 btn btn-primary export-btn" *ngIf="totalrec > 0" (click)="ExportCsv()">Export</button>

      <div class="row">
        <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
                 [value]="this.records" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
                 responsiveLayout="stack" dataKey="id" [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
                 styleClass="p-datatable-offlineconsultants" [paginator]="true"
                 currentPageReportTemplate="{first} to {last} of {totalRecords}">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="email" pFrozenColumn>
                Email
                <p-sortIcon field="email"></p-sortIcon>
              </th>

              <th pSortableColumn="website" pFrozenColumn>
                Website
                <p-sortIcon field="website"></p-sortIcon>
              </th>

              <th pSortableColumn="dated" pFrozenColumn>
                Dated
                <p-sortIcon field="dated"></p-sortIcon>
              </th>

              <th pSortableColumn="senttoRGR" pFrozenColumn>
                SenttoRGR
                <p-sortIcon field="senttoRGR"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-Items>
            <tr>
              <td>{{Items.email}}</td>
              <td>{{Items.website}}</td>
              <td>{{Items.dated}}</td>
              <td>{{Items.senttoRGR}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No record found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
<div>
</div>
<div *ngIf="spinner == 1 ">
  <ngx-spinner bdOpacity=0.1
               bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
               [fullScreen]="false"
               template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
  </ngx-spinner>
</div>

<div *ngIf="spinner == 2 ">
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p *ngIf="total > 0" style="color: white">
      Total : {{total}} <span class="paddingleft26" *ngIf="processed > 0">Processed : {{processed}}</span>
      <span class="paddingleft26" *ngIf="success > 0">Success : {{success}}</span>
      <span class="paddingleft26" *ngIf="failed > 0">Failed : {{failed}}</span>
    </p>
  </ngx-spinner>
</div>




