import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';


import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';

// BOOTSTRAP COMPONENTS

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';

// CONSULTANT

import { AllConsultantTitleComponent } from './Layout/Components/page-title/page-all-consultant.component';
import { AllUsersTitleComponent } from './Layout/Components/page-title/page-all-users.component';
import { AreasTitleComponent } from './Layout/Components/page-title/page-areas.component';
import { SpecializationsTitleComponent } from './Layout/Components/page-title/page-specializations.component';
import { BackButtonTitleComponent } from './Layout/Components/page-title/page-back-button.component';
import { ConsultantDetailsTitleComponent } from './Layout/Components/page-title/page-consultant-details.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';

// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
import { TabsComponent } from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables


// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts


// Chart.js Examples

import { ToastrModule } from 'ngx-toastr';

import { ArchwizardModule } from 'angular-archwizard';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
// import { SearchComponent } from './DemoPages/consultant/search/search.component';
import { ConfirmationDialogComponent } from './DemoPages/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './DemoPages/confirmation-dialog/confirmation-dialog.service';
// import { BtnConsultantDashboard } from './DemoPages/Dashboards/consultantdashboard/btn-consultantdashboard.component';
import { DashboardComponent } from './DemoPages/Dashboards/dashboard/dashboard.component';
import { DataTablesModule } from "angular-datatables";
// import { Ng2SmartTableModule } from 'ng2-smart-table';

//PRIMENG
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { NoCacheHeadersInterceptor } from './cache/NoCacheHeadersInterceptor';
//PRIMENG
import { ImageCropperModule } from "ngx-image-cropper";
import { CsvService } from './services/csv.service';
import { NgxAutocompleteModule } from 'ngx-angular-autocomplete';
import { AllStatsComponent } from './DemoPages/all-stats/all-stats.component';
import { DomainCsvFilesComponent } from './DemoPages/domain-csv-files/domain-csv-files.component';
import { ApiTestComponent } from './DemoPages/api-test/api-test.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CsvParserComponent } from './DemoPages/csv-parser/csv-parser.component';
import { SftpDownloaderComponent } from './DemoPages/sftp-downloader/sftp-downloader.component';
import { UpdateAccountComponent } from './DemoPages/update-account/update-account.component';
import { TableColumnsComponent } from './DemoPages/table-columns/table-columns.component';
import { EmailSortingStatsComponent } from './DemoPages/email-sorting-stats/email-sorting-stats.component';
import { SearchEmailComponent } from './DemoPages/search-email/search-email.component';
import { RgrsuppressionsComponent } from './DemoPages/rgr-suppressions/rgr-suppressions.component';
import { SuppressionsStatsComponent } from './DemoPages/suppressions-stats/suppressions-stats.component';
import { OngageExportComponent } from './DemoPages/ongage-export/ongage-export.component';
import { EmailSenderLogComponent } from './DemoPages/email-sender-log/email-sender-log.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT + Title Page

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,
    AllUsersTitleComponent,
    AreasTitleComponent,
    SpecializationsTitleComponent,
    BackButtonTitleComponent,
    // CONSULTANT
    AllConsultantTitleComponent,
    ConsultantDetailsTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES




    // User Pages

    LoginComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,
    ConfirmationDialogComponent,
    DashboardComponent,
    AllStatsComponent,
    DomainCsvFilesComponent,
    ApiTestComponent,
    UnauthorizedComponent,
    CsvParserComponent,
    SftpDownloaderComponent,
    UpdateAccountComponent,
    TableColumnsComponent,
    EmailSortingStatsComponent,
    SearchEmailComponent,
    RgrsuppressionsComponent,
    SuppressionsStatsComponent,
    OngageExportComponent,
    EmailSenderLogComponent
  ],
  imports: [
    // Ng2SmartTableModule,
    NgxAutocompleteModule,
    NgMultiSelectDropDownModule.forRoot(),
    ImageCropperModule,
    DataTablesModule,
    BrowserModule,
    AppRoutingModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    AngularEditorModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //PRIMENG
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    //AGGRID

    // Charts

    ChartsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ArchwizardModule,


  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: NoCacheHeadersInterceptor,
    multi: true
  },CsvService,DatePipe, ConfirmationDialogService,MessageService,
    {
      provide:

        PERFECT_SCROLLBAR_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
