<div>
  <button type="button" class="mt-1 btn btn-primary copybtn" (click)="Copy()">Copy</button>
  <br />
  <table #statsTable id="statsTable" class="res-table">
    <thead>
      <tr>
        <th scope="col">No</th>
        <th scope="col">Description</th>
        <th scope="col">TotalRecords</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of stats; index as i" [ngClass]="d.TotalRecords == 0 ? 'red' : ''">
        <td data-label="No">
          {{i+1 }}
        </td>
        <td data-label="Description">
          {{d.Description }}
        </td>
        <td data-label="TotalRecords">
          {{d.TotalRecords }}
        </td>
      </tr>

    </tbody>
  </table>
</div>
<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
