


<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>



    <div class="row margin-top36">
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">From</label>
        <input name="vendor"
               id="exampleEmail" placeholder="" [(ngModel)]="datedfrom" [ngModelOptions]="{standalone: true}" type="date" class="form-control">
      </div>
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">To</label>
        <input name="vendor"
               id="exampleEmail" placeholder="" [(ngModel)]="datedto" [ngModelOptions]="{standalone: true}" type="date" class="form-control">
      </div>
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">Vertical</label>
        <select (change)="ChangeVertical($event)" [(ngModel)]="vertical" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of verticals" [value]="t.vertical">
            {{t.vertical}}
          </option>
        </select>
      </div>

      <div class="col-md-3 floatleft position-relative form-group">
        <label class="">Upload Type</label>
        <ng-multiselect-dropdown [placeholder]="'Select Upload Type'"
                                 [settings]="dropdownSettings"
                                 [data]="dropdownList"
                                 [(ngModel)]="selectedItems"
                                 (onSelect)="onItemSelect($event)"
                                 (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
      </div>

    </div>
    <button type="button" class="mt-1 btn btn-primary" (click)="GetRecords()">Filter</button>

    <h1>Total Failed Records: {{tfailed}}</h1>

    <div class="row">
      <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
               [value]="this.records" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
               responsiveLayout="stack" dataKey="id" [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
               styleClass="p-datatable-offlineconsultants" [paginator]="true"
               currentPageReportTemplate="{first} to {last} of {totalRecords}">
       
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="email" pFrozenColumn>
              Email
              <p-sortIcon field="email"></p-sortIcon>
            </th>

            <th pSortableColumn="reason" pFrozenColumn>
              Reason
              <p-sortIcon field="reason"></p-sortIcon>
            </th>

            <th pSortableColumn="dated" pFrozenColumn>
              Dated
              <p-sortIcon field="dated"></p-sortIcon>
            </th>

            <th pSortableColumn="vertical" pFrozenColumn>
              Vertical
              <p-sortIcon field="vertical"></p-sortIcon>
            </th>
            <th pSortableColumn="uploadtype" pFrozenColumn>
              Upload Type
              <p-sortIcon field="uploadtype"></p-sortIcon>
            </th>
            
          </tr>
          <tr>
            <th *ngFor="let col of cols">
              <input pInputText type="text" (input)="dt2.filter($event.target.value, col.field, col.filterMatchMode)" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Items>
          <tr>
            <td>{{Items.email}}</td>
            <td>{{Items.reason}}</td>
            <td>{{Items.dated}}</td>
            <td>{{Items.vertical}}</td>
            <td>{{Items.uploadtype}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No record found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  </div>
<div>
</div>
<div *ngIf="spinner == 1 ">
  <ngx-spinner bdOpacity=0.1
               bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
               [fullScreen]="false"
               template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
  </ngx-spinner>
</div>





