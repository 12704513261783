import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {  CsvService } from 'src/app/services/csv.service';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';

@Component({
  selector: 'app-suppressions-stats',
  templateUrl: './suppressions-stats.component.html',
  styleUrls: ['./suppressions-stats.component.scss']
})
export class SuppressionsStatsComponent implements OnInit {
  id: any;
  type = 'positive';
  user = '2';
  filename = '';
  file: any;
  dated='';

  faPlus = faPlus;
  heading = 'Suppressions Stats';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  stats: any;
  totalRecords = 0;
  constructor(private route: ActivatedRoute, private router: Router,public _csvService: CsvService, private csvUpdateService: CsvUpdateService, private SpinnerService: NgxSpinnerService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }

  ngOnInit(): void {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    this.dated = formattedDate;
    this.GetAllStats();
  }
  GetAllStats() {
    this.SpinnerService.show();
    this.csvUpdateService.GetSuppressionsStats()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.stats = response;
          this.totalRecords = this.stats.length;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);
  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }


  UploadCsv() {
    if (this.filename == '' || this.filename == null
      || this.filename == undefined
    ) {

    }
    else {
      this._csvService.dataStr = this._csvService.dataStr.filter(item => item != '' && item != '\r');
      const data = {
        data: this._csvService.dataStr,
        type: this.type,
        user: this.user,
        dated: this.dated
      };
      this.SpinnerService.show();
      this.csvUpdateService.EmailSortingInsert(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            if (response.res == '1') {

            }
          },
          error => {
            this.SpinnerService.hide();
          });
    }
  }

}
