import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
  providedIn: 'root'
})
export class OngageService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL + '/api/';
  }
  OngageGetExportId(): Observable<any> {
    return this.http.get(this.baseUrl + 'ongagegetexportid');
  }
  DownloadOngageFile(obj): Observable<any> {
    return this.http.post(this.baseUrl + 'downloadongagefile',obj);
  }
  getongageStats(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getongageStats', data);
  }
  InsertOngage(data): Observable<any> {
    return this.http.post(this.baseUrl + 'InsertOngage', data);
  }
  EmailSenderFailedLog(data): Observable<any> {
    return this.http.post(this.baseUrl + 'emailsenderfailedlog', data);
  }
  GetVerticals(): Observable<any> {
    return this.http.get(this.baseUrl + 'getverticals');
  }
  
  EmailSenderFailedCount(): Observable<any> {
    return this.http.get(this.baseUrl + 'emailsenderfailedcount');
  }
}
