import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SftpService } from 'src/app/services/sftp.service';
import { saveAs } from 'file-saver';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';
@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.css']
})
export class UpdateAccountComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  dir = '';
  dirs: any;
  files: any;
  accounts: any;
  accountId = '';
  constructor(private router: Router,private route: ActivatedRoute,private formBuilder: FormBuilder, private SftpService: SftpService, private SpinnerService: NgxSpinnerService) {
    this.form = this.formBuilder.group(
      {
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        host: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        keepaliveInterval: [
          2000,
          [
            Validators.required,
          ]
        ],
        keepaliveCountMax: [
          20,
          [
            Validators.required,
          ]
        ],
        port: [
          0,
          [
            Validators.required,
          ]
        ],
        type: [
          '',
          [
            Validators.required,
          ]
        ],

        

      }


    )
  }
  GetSftpAccountById() {
    const data = {
      account: this.route.snapshot.paramMap.get('id'),
    };
    this.SpinnerService.show();
    this.SftpService.GetSftpAccountById(data)
      .subscribe(
        response => {
          console.log(response);
          this.form.controls['host'].setValue(response[0].host);
          this.form.controls['name'].setValue(response[0].name);
          this.form.controls['username'].setValue(response[0].username);

          this.form.controls['password'].setValue(response[0].password);

          this.form.controls['keepaliveInterval'].setValue(response[0].keepaliveInterval);
          this.form.controls['keepaliveCountMax'].setValue(response[0].keepaliveCountMax);
          this.form.controls['port'].setValue(response[0].port);

          this.form.controls['type'].setValue(response[0].type);
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    const data = {
      id: this.route.snapshot.paramMap.get('id'),
      name: this.form.controls['name'].value,
      host: this.form.controls['host'].value,
      username: this.form.controls['username'].value,
      password: this.form.controls['password'].value,
      keepaliveInterval: this.form.controls['keepaliveInterval'].value,
      keepaliveCountMax: this.form.controls['keepaliveCountMax'].value,
      port: this.form.controls['port'].value,
      type: this.form.controls['type'].value,

    };
    this.SpinnerService.show();
    this.SftpService.UpdateFtpSftpAccount(data)
      .subscribe(
        response => {
          this.onReset();
          this.SpinnerService.hide();
          this.router.navigate(['/sftp-downloader/' + Global.guid]);
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  ngOnInit(): void {
    this.GetSftpAccountById();
  }

}
