import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SftpService } from 'src/app/services/sftp.service';
import { saveAs } from 'file-saver';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';
@Component({
  selector: 'app-sftp-downloader',
  templateUrl: './sftp-downloader.component.html',
  styleUrls: ['./sftp-downloader.component.css']
})
export class SftpDownloaderComponent implements OnInit {
  path = '';
  prev = '';
  form: FormGroup;
  submitted = false;

  dir = '';
  dirs: any;
  files: any;
  accounts: any;
  accountId = '';
  id: any;
  constructor(private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder, private SftpService: SftpService, private SpinnerService: NgxSpinnerService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
    this.form = this.formBuilder.group(
      {
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        host: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(2)
          ]
        ],
        keepaliveInterval: [
          2000,
          [
            Validators.required,
          ]
        ],
        keepaliveCountMax: [
          20,
          [
            Validators.required,
          ]
        ],
        port: [
          0,
          [
            Validators.required,
          ]
        ],
        type: [
          '',
          [
            Validators.required,
          ]
        ],



      }


    )
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    const data = {
      name: this.form.controls['name'].value,
      host: this.form.controls['host'].value,
      username: this.form.controls['username'].value,
      password: this.form.controls['password'].value,
      keepaliveInterval: this.form.controls['keepaliveInterval'].value,
      keepaliveCountMax: this.form.controls['keepaliveCountMax'].value,
      port: this.form.controls['port'].value,
      type: this.form.controls['type'].value,
    };
    this.SpinnerService.show();
    this.SftpService.AddFtpSftpAccount(data)
      .subscribe(
        response => {
          this.onReset();
          this.SpinnerService.hide();
          this.GetSftpAccounts();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  ngOnInit(): void {
    this.GetSftpAccounts();
  }

  GetSftpAccounts() {
    this.SpinnerService.show();
    this.SftpService.GetSftpAccounts()
      .subscribe(
        response => {
          this.accounts = response;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  changeAccount(event) {
    this.path = '';
    if (event.target.value != '') {
      this.accountId = event.target.value;
      this.getSFTPDirectory('');
    }
    else {
      this.dirs = [];
    }
  }
  updateAccount() {
    if (this.accountId != '' && this.accountId != null && this.accountId != undefined) {
      var url = '/update-account/' + this.accountId;
      this.router.navigate([url]);
    }
  }

  getSFTPDirectory(path) {
    const data = {
      account: this.accountId,
      path: path
    };
    this.SpinnerService.show();
    this.SftpService.getSFTPDirectory(data)
      .subscribe(
        response => {
          this.files = response.data;
          var d = this.files.sort((a, b) => (a.modifyTime < b.modifyTime ? -1 : 1));
          this.files = d;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }

  DownloadFile(file) {
    var remotePath = '';
    if (this.path == '') {
      remotePath = "/" + file;
    }
    else {
      remotePath = "/" + this.path + "/" + file;
    }

    const data = {
      filename: file,
      dir: this.path,
      account: this.accountId,
      remotePath: remotePath
    };
    this.SpinnerService.show();
    this.SftpService.DownloadFile(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          saveAs(response, data.filename);
        },
        error => {

          this.SpinnerService.hide();
          //this.toastr.error('An Error Occurred!', 'Error!');
        });
  }
  OpenFolder(path) {
    if (this.path == '') {
      this.path = this.path + path;
    }
    else {
      this.path = this.path + "/" + path;
    }
    this.getSFTPDirectory(this.path);
  }
  Back() {
    if (this.path.indexOf("/") !== -1) {
      var p = this.path.slice(0, this.path.lastIndexOf("/"));
      this.path = p;
    }
    else {
      this.path = "";
    }
    this.getSFTPDirectory(this.path);
  }

  GetDate(time) {
    var date = new Date(time)
    return date;
  }
  GetSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    //var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }
}
