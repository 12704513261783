


<div  class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>
    <div class="row margin-top36">
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">Select Database</label>
        <select (change)="selectDatabase($event)" [(ngModel)]="db" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of databases" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">Select Table</label>
        <select (change)="selectTable($event)" [(ngModel)]="tbl" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of tables" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
      <div class="col-md-6 floatleft position-relative form-group paddingleft0">
        </div>

        <div class="col-md-3 floatleft position-relative form-group paddingleft0">
          <label for="exampleFile">CSV File</label>
          <input #fileUploadSimple [accept]="'.csv'" type="file" class="form-control-file"
                 (change)="importDataFromCSV($event)">
        </div>

        <div class="col-md-3 floatleft position-relative form-group hidden">
          <label for="exampleEmail">Vendor</label>
          <input name="vendor"
                 id="exampleEmail" placeholder="" [(ngModel)]="vendor" [ngModelOptions]="{standalone: true}" type="text" class="form-control">
        </div>

        <div class="col-md-5 floatleft position-relative form-group">
          <label for="exampleEmail">Address Column(<small>the number of address column or to skip leave blank</small>)</label><input name="email"
                                                                                                                                     id="exampleEmail" placeholder="" [(ngModel)]="addressCol" [ngModelOptions]="{standalone: true}" type="number" class="form-control">
        </div>
      </div>
    </div>
</div>
<div>
<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview"
  [value]="this._csvService.csvPrev" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
  responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
  styleClass="p-datatable-offlineconsultants" [paginator]="false"
  [ngClass]="_csvService.csvPrev.length > 0 ? 'heightauto' : 'height0'"
  currentPageReportTemplate=""
  >
  
  <ng-template pTemplate="header">
    <tr>
      <th class="width90"  *ngFor="let p of this.HeaderName(this._csvService.propertyNames); index as i;">{{p}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-Items>
    <tr>
      <td class="width100"  *ngFor="let c of SplitByComma(Items);">{{c}}</td>
     </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>

<br>

<p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
  [value]="this.mappedData" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
  responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
  styleClass="p-datatable-offlineconsultants" [paginator]="true"
  [ngClass]="_csvService.propertyNames.length > 0 ? 'heightauto' : 'height0'"
  currentPageReportTemplate="{first} to {last} of {totalRecords}"
  >
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn>Master</th>
      <th pFrozenColumn>Mapped Column</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-Items>
    <tr>
      <td>{{Items.column}}</td>
      <td>
        <select [(ngModel)]="Items.selectedValue" [ngModelOptions]="{standalone: true}"  >
          <option value=""></option>
          <option *ngFor="let p of _csvService.propertyNames" [value]="p">
            {{p}}
          </option>
        </select>
       </td>
     </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<br>
<button type="button" class="mt-1 btn btn-primary" (click)="UploadCsv()">Upload</button>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p *ngIf="total > 0" style="color: white">
    Total : {{total}} <span class="paddingleft26" *ngIf="processed > 0">Processed : {{processed}}</span>
    <span class="paddingleft26" *ngIf="success > 0">Success : {{success}}</span>
    <span class="paddingleft26" *ngIf="failed > 0">Failed : {{failed}}</span>
  </p>
</ngx-spinner>

