


<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>
    <div class="row margin-top36">
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">Select Database</label>
        <select (change)="selectDatabase($event)" [(ngModel)]="db" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of databases" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">Select Table</label>
        <select (change)="selectTable($event)" [(ngModel)]="tbl" class="form-control">
          <option value=""></option>
          <option *ngFor="let t of tables" [value]="t.name">
            {{t.name}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
<div>
  <p>{{columnsdt}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p *ngIf="total > 0" style="color: white">
    Total : {{total}} <span class="paddingleft26" *ngIf="processed > 0">Processed : {{processed}}</span>
    <span class="paddingleft26" *ngIf="success > 0">Success : {{success}}</span>
    <span class="paddingleft26" *ngIf="failed > 0">Failed : {{failed}}</span>
  </p>
</ngx-spinner>

