import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
  providedIn: 'root'
})
export class AllstatService {
 baseUrl: string;
    constructor(private http: HttpClient) {
      this.baseUrl = Global.apiURL + '/api/';
    }
  
    getAllStats(): Observable<any> {
      return this.http.get(this.baseUrl + 'getallstats');
    }
}
