import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllstatService } from 'src/app/services/allstat.service';
import { SearchEmailService } from '../../services/search-email.service';
import { Global } from '../common/global';

@Component({
  selector: 'app-search-email',
  templateUrl: './search-email.component.html',
  styleUrls: ['./search-email.component.css']
})
export class SearchEmailComponent implements OnInit {
  id: any;
  heading = 'Search Email';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  alldata: any;
  founddata: any;
  notfounddata: any;
  email = '';
  seconds: number = 0;
  intervalId: any;
  constructor(private router: Router, private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, private searchEmailService: SearchEmailService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }
  ngOnInit(): void {
    this.founddata = [];
    this.notfounddata = [];
  }
  Search(e) {
    this.SearchEmailInAllDbs();
  }
  SearchEmailInAllDbs() {
    const data = {
      email: this.email,
    };
    this.SpinnerService.show();
    this.intervalId = setInterval(() => {
      this.seconds++;
    }, 1000);
    this.searchEmailService.SearchEmailInAllDbs(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          clearInterval(this.intervalId);
          this.alldata = response;
          this.notfounddata = this.alldata.filter(x => x.Result == 0);
          this.founddata = this.alldata.filter(x => x.Result > 0);
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  DeleteRecordFromDb(db, tbl, col) {
    const data = {
      email: this.email,
      db: db,
      tbl: tbl,
      col: col
    };
    this.searchEmailService.DeleteRecordFromDb(data)
      .subscribe(
        response => {
          this.founddata = this.founddata.filter(x => x.DatabaseName != db
            && x.TableName != tbl
            && x.ColumnName != col
          );
          	
        },
        error => {
        });
  }
  

}
