import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiTestService } from 'src/app/services/api-test.service';
import { Global } from '../common/global';

@Component({
  selector: 'app-api-test',
  templateUrl: './api-test.component.html',
  styleUrls: ['./api-test.component.css']
})
export class ApiTestComponent implements OnInit {
  id: any;
  cols: Array<{ [key: string]: any }> = [{
  }]
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  constructor(private router: Router,private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, private apiTestService: ApiTestService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }

  ngOnInit(): void {
    this.cols = [];
  }
  TestCharithAPIs() {
    this.cols = [];
    this.SpinnerService.show();
    this.apiTestService.GetNewEmail()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          var arr = JSON.parse(response);
          this.UpdateCharitAPI(arr[0], 'click');
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  UpdateCharitAPI(email,type) {
    const data = {
      type: type,
      email: email
    };
    var d = 'http://www.livauto.com/?key=d0bbe992-b166-40de-894a-4673420510eb&email=[email]&type=[type]&name=refi';
    d = d.replace("[email]", email);
    d = d.replace("[type]", type);

    this.apiTestService.UpdateCharithAPI(data)
      .subscribe(
        response => {
          var res = '';
          if (response.res == 200) {
            res = '1';
          }
          else {
            res = '0';
          }

          this.cols.push
            ({
              url: d,
              res: res,
            });

          if (type == 'click') {
            this.UpdateCharitAPI(email, 'open');
          }
          if (type == 'open') {
            this.UpdateCharitAPI(email, 'bounce');
          }
          if (type == 'bounce') {
            this.UpdateCharitAPI(email, 'complain');
          }
          if (type == 'complain') {
            this.UpdateCharitAPI(email, 'unsubscribe');
          }

        },
        error => {
          this.cols.push
            ({
              url: d,
              res: '0',
            });

          if (type == 'click') {
            this.UpdateCharitAPI(email, 'open');
          }
          if (type == 'open') {
            this.UpdateCharitAPI(email, 'bounce');
          }
          if (type == 'bounce') {
            this.UpdateCharitAPI(email, 'complain');
          }
          if (type == 'complain') {
            this.UpdateCharitAPI(email, 'unsubscribe');
          }
        });
  }


  TestRGRAPIs() {
    this.cols = [];
    this.SpinnerService.show();
    this.apiTestService.GetNewEmail()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          var arr = JSON.parse(response);
          this.UpdateRGRAPIsRefinance(arr[0]);
        },
        error => {
          this.SpinnerService.hide();
        });
  }

  UpdateRGRAPIsRefinance(email) {
    const data = {
      email: email
    };

    this.apiTestService.UpdateRGRAPIsRefinance(data)
      .subscribe(
        response => {
          var res = '';
          if (response.res == 200) {
            res = '1';
          }
          else {
            res = '0';
          }

          this.cols.push
            ({
              url: "http://gorefinance.site/RGRAPI/",
              res: res,
            });
          this.UpdateRGRAPIsSolar(email);
        },
        error => {
          this.cols.push
            ({
              url: "http://gorefinance.site/RGRAPI/",
              res: '0',
            });
          this.UpdateRGRAPIsSolar(email);
        });
  }
  UpdateRGRAPIsSolar(email) {
    const data = {
      email: email
    };
    this.apiTestService.UpdateRGRAPIsSolar(data)
      .subscribe(
        response => {
          var res = '';
          if (response.res == 200) {
            res = '1';
          }
          else {
            res = '0';
          }

          this.cols.push
            ({
              url: "http://gorefinance.site/RGRAPI/Solar",
              res: res,
            });

        },
        error => {
          this.cols.push
            ({
              url: "http://gorefinance.site/RGRAPI/Solar",
              res: '0',
            });

        });



  }

  TestHomeWarrantyAPI() {
    this.cols = [];
    this.SpinnerService.show();
    this.apiTestService.GetNewEmail()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          var arr = JSON.parse(response);
          this.UpdateHomeWarrantyAPI(arr[0]);
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  
  UpdateHomeWarrantyAPI(email) {
    const data = {
      email: email
    };
    this.apiTestService.UpdateHomeWarrantyAPI(data)
      .subscribe(
        response => {
          var res = '';
          if (response.res == 200) {
            res = '1';
          }
          else {
            res = '0';
          }

          this.cols.push
            ({
              url: "http://hw.livauto.com/",
              res: res,
            });

        },
        error => {
          this.cols.push
            ({
              url: "http://hw.livauto.com/",
              res: '0',
            });

        });



  }

}
