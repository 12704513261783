


<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>

    <div class="row margin-top36">
      <div class="col-md-3 floatleft position-relative form-group paddingleft0">
        <label for="exampleFile">CSV File</label>
        <input #fileUploadSimple accept=".txt, .csv" type="file" class="form-control-file"
               (change)="importDataFromCSV($event)">
      </div>


      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">Type</label>
        <select class="mb-2 form-control-md form-control" [(ngModel)]="type">
          <option value="positive">positive</option>
          <option value="suppression">suppression</option>
          <option value="bounced">bounced</option>
        </select>
      </div>

      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">User</label>
        <select class="mb-2 form-control-md form-control" [(ngModel)]="user">
          <option [value]="1">Mark</option>
          <option [value]="2">Charith</option>
        </select>
      </div>
      <div class="col-md-2 floatleft position-relative form-group">
        <label for="exampleEmail">Date</label>
        <input name="vendor"
               id="exampleEmail" placeholder="" [(ngModel)]="dated" [ngModelOptions]="{standalone: true}" type="date" class="form-control">

      </div>


    </div>
    <button type="button" class="mt-1 btn btn-primary" (click)="UploadCsv()">Upload</button>

    <div class="row">
      <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
               [value]="this.stats" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
               responsiveLayout="stack" dataKey="id" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
               styleClass="p-datatable-offlineconsultants" [paginator]="true"
               currentPageReportTemplate="{first} to {last} of {totalRecords}">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn>Dated</th>
            <th pFrozenColumn>User</th>
            <th pFrozenColumn>Positives</th>
            <th pFrozenColumn>Suppressions</th>
            <th pFrozenColumn>Bounced</th>
            <th pFrozenColumn>Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Items>
          <tr>
            <td>{{Items.fdated}}</td>
            <td>{{Items.userid}}</td>
            <td>{{Items.positives}}</td>
            <td>{{Items.suppressions}}</td>
            <td>{{Items.bounced}}</td>
            <td>{{Items.total}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No record found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  </div>
<div>
</div>
<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>

