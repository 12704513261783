import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { CombineTables, CsvService, SelectColumns } from 'src/app/services/csv.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../../services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../common/global';
import { interval, Subscription } from 'rxjs';
import { OngageService } from '../../services/ongage.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-email-sender-log',
  templateUrl: './email-sender-log.component.html',
  styleUrls: ['./email-sender-log.component.sass']
})
export class EmailSenderLogComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  spinner = 1;
  domainslength = 0;
  day :any;
  totalRec = 0;
  amountofdata = 10;

  closeResult = '';
  showingrecords = '';
  heading = 'Domains Filter Custom';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  
  from = '';
  to = '';
  totalrec = 0;
  totalrecTxt = '';
  records = [];
  totaldomainsdata: any;
  totaldomains = 0;
  countrec = 0;
  
  id: any;
  datedfrom = '';
  datedto = '';

  cols: any[] = [];

  verticals: any;
  vertical = '';
  tfailed = 0;

  uploadtypes: any;
  selecteduploadtypes = [];

  cities: any;
  selectedcities = [];

  constructor(public csvUpdateService: CsvUpdateService,public _csvService: CsvService, public router: Router, private route: ActivatedRoute, public utilityService: UtilityService, private SpinnerService: NgxSpinnerService, private modalService: NgbModal, public ongageService: OngageService, private toastr: ToastrService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }


  ngOnInit(): void {
    this.uploadtypes = this.csvUpdateService.uploadtypes.map(({ type }) => ({ type }));
    this.uploadtypes = [...new Map(this.uploadtypes.map(item =>
      [item['type'], item])).values()];

    this.cols = [
      { field: "email", header: "Email" },
    ];
    this.GetRecords();
    this.GetVerticals();

    this.dropdownList = [
      {
        type: "RD"
      },
      {
        type: "RDv1"
      },
      {
        type: "GLOv1"
      },
      {
        type: "GLO"
      },
      {
        type: "SLS"
      },
      {
        type: "MAN"
      },
      {
        type: "YRH"
      },
    ];
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'type',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 12,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  RemoveUpload(val) {
    this.selecteduploadtypes = this.selecteduploadtypes.filter(item => item != val);
  }
  SelectUpload(event) {
    var len = this.selecteduploadtypes.filter(item => item == event.city).length;
    if (len == 0) {
      this.selecteduploadtypes.push(event.type);
    }
  }
  
  GetVerticals() {
    this.ongageService.GetVerticals()
      .subscribe(
        response => {
          this.verticals = response;
          this.tfailed = this.verticals[0].f;
        },
        error => {
        });
  }
  ChangeVertical(event) {

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 
  GetRecords() {
    var uploadtypes = this.GetStrFromArr(this.selectedItems);
    const data = {
      dateFrom: this.datedfrom,
      dateTo: this.datedto,
      vertical: this.vertical,
      uploadtypes: uploadtypes
    };
    this.spinner = 1;
    this.SpinnerService.show();
    this.ongageService.EmailSenderFailedLog(data)
      .subscribe(
        items => {
          this.SpinnerService.hide();
          this.records = items.recordsets[0];
          this.totalrec = items.recordsets[0].length;
          if (this.totalrec > 10) {
            this.showingrecords = "showing first 10 records as sample";
          }
          else {
            this.showingrecords = "showing all " + this.totalrec + " records";
          }
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });

  }
  GetStrFromArr(Arr) {
    if (Arr.length == 0) {
      return '';
    }
    else {
      var arr2 = [];
      for (let i = 0; i < Arr.length; i++) {
        var value = "'[val]'";
        value = value.replace("[val]", Arr[i].type);
        arr2.push(value);
      }
      return arr2.toString()
    }
  }

}
