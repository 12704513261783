<div>
  <button type="button" class="mt-1 btn btn-primary" (click)="TestCharithAPIs()">Test Charith APIs</button>
  <button type="button" class="mt-1 btn btn-primary" (click)="TestRGRAPIs()">Test RGR APIs</button>
  <button type="button" class="mt-1 btn btn-primary" (click)="TestHomeWarrantyAPI()">Test HomeWarranty API</button>
  <br />
  <br />
  <table class="res-table" *ngIf="cols.length > 0">
    <!--<thead>
      <tr>
        <th scope=""></th>
      </tr>
    </thead>-->
    <tbody>
      <tr *ngFor="let d of cols; index as i">
        <td data-label="">
          {{d.url }} <br />
          <span *ngIf="d.res == '1'">[test ok]</span>
          <span class="red" *ngIf="d.res == '0'">[test failed]</span>
        </td>
      </tr>

    </tbody>
  </table>
</div>
<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
