import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { CsvUpdateService } from '../../services/csv-update.service';
import { CombineTables, CsvService } from '../../services/csv.service';
import { DatabaseService } from '../../services/database.service';
import { UserService } from '../../services/user.service';
import { Global } from '../common/global';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss']
})
export class TableColumnsComponent implements OnInit {
  db = '';
  tbl = '';
  databases: any;
  tables: any;
  id: any;
  filename = '';
  disabled: boolean = true;
  file: any;
  processed = 0;
  vendor = 'RefinanceCopy';
  total = 0;
  success = 0;
  failed = 0;
  failedtxt = '';
  bshowfailed = false;
  statid: any;
  mySub: Subscription;
  addressCol = 0;
  cols: string[] = [];
  checkdomains: any;
  faPlus = faPlus;
  totalRec = 0;
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  master = '';
  columnsdt = '';
  constructor(private databaseService: DatabaseService, private route: ActivatedRoute, public _csvService: CsvService, private csvUpdateService: CsvUpdateService, private confirmationDialogService: ConfirmationDialogService, private userService: UserService, private http: HttpClient, private SpinnerService: NgxSpinnerService, private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }
  ngOnInit() {
    this.GetRobertDatabases();
  }
  GetRobertDatabases() {
    this.databaseService.GetRobertDatabases()
      .subscribe(
        response => {
          this.databases = response;
        },
        error => {
        });
  }
  selectDatabase(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tables = response;
        },
        error => {
        });
  }
  selectTable(event) {
    //this.selectedTable = event.target.value;
    this.master = this.db + '.dbo.' + this.tbl;
    this.GetAllTableColumns();

  }
  GetAllTableColumns() {
    const data = {
      table: this.master,
    };
    this.csvUpdateService.GetAllTableColumns(data)
      .subscribe(
        response => {
          this.columnsdt = response[0].columns;
        },
        error => {
        });
  }
}
