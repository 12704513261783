


<div class="mb-3 card">
  <div class="card-body">
    <h5 class="card-title"></h5>

    <div class="row">
      <p-table #dt2 [resizableColumns]="true" stateStorage="local" stateKey="mappedCols"
               [value]="this.stats" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
               responsiveLayout="stack" dataKey="id" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
               styleClass="p-datatable-offlineconsultants" [paginator]="true"
               currentPageReportTemplate="{first} to {last} of {totalRecords}">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn>Dated</th>
            <th pFrozenColumn>Ben</th>
            <th pFrozenColumn>scalternatives.com</th>
            <th pFrozenColumn>www.cseclub.com</th>
            <th pFrozenColumn>Charith Email sorting</th>
            <th pFrozenColumn>Kartik</th>
            <th pFrozenColumn>idealhomeliving.com</th>
            <th pFrozenColumn>Mark Email sorting</th>
            <th pFrozenColumn>thehomeasset.com</th>
            <th pFrozenColumn>limitcoverage.com</th>
            <th pFrozenColumn>RgrRyan</th>
            <th pFrozenColumn>Day Total</th>
            <th pFrozenColumn>Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Items>
          <tr>

            <td>{{Items.fdated}}</td>
            <td>{{Items.ben}}</td>
            <td>{{Items.scalternatives}}</td>
            <td>{{Items.cseclub}}</td>
            <td>{{Items.charith}}</td>
            <td>{{Items.Kartik}}</td>
            <td>{{Items.idealhomeliving}}</td>
            <td>{{Items.markemailsorting}}</td>
            <td>{{Items.thehomeasset}}</td>
            <td>{{Items.limitcoverage}}</td>
            <td>{{Items.rgrryan}}</td>
            <td>{{Items.dayTotal}}</td>
            <td>{{Items.total}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No record found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  </div>
<div>
</div>
<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>

