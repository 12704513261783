import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CombineTables, CsvService } from 'src/app/services/csv.service';
import { interval, Subscription } from 'rxjs';
import * as moment from 'moment';
import { UtilityService } from '../../services/utility.service';
import { Global } from '../common/global';
import { DatabaseService } from '../../services/database.service';
import { CsvUpdateService } from '../../services/csv-update.service';
@Component({
  selector: 'app-domain-csv-files',
  templateUrl: './domain-csv-files.component.html',
  styleUrls: ['./domain-csv-files.component.sass']
})
export class DomainCsvFilesComponent implements OnInit {
  databases: any;
  tables: any;
  id: any;
  domains = 0;
  checkdomains: any;
  faPlus = faPlus;
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  master = '';
  db = '';
  tbl = '';
  emailcol = '';
  constructor(private csvUpdateService: CsvUpdateService,private databaseService:DatabaseService,private route: ActivatedRoute,private utilityService: UtilityService, private userService: UserService, private http: HttpClient, private SpinnerService: NgxSpinnerService, private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }

  ngOnInit() {
    
    this.GetRobertDatabases();
  }
  GetRobertDatabases() {
    this.databaseService.GetRobertDatabases()
      .subscribe(
        response => {
          this.databases = response;
        },
        error => {
        });
  }
  selectDatabase(event) {
    const data = {
      db: event.target.value
    };
    this.databaseService.GetTablesByDatabase(data)
      .subscribe(
        response => {
          this.tables = response;
        },
        error => {
        });
  }
  selectTable(event) {
    this.master = this.db + '.dbo.' + this.tbl;
    const data = {
      table: this.master,
    };
    this.csvUpdateService.CheckEmailColumn(data)
      .subscribe(
        response => {
          if (response.length > 0) {
            this.GetExportedAndNonExportedDomains(response[0].name);
          }
        },
        error => {
        });
  }
  CreateDomainFiles() {
    this.SpinnerService.show();
    const userid = localStorage.getItem("token");

    const data = {
      userid: userid,
      domains: this.domains,
      table: this.master,
      emailcol: this.emailcol
    };
    this.utilityService.CreateDomainFiles(data)
      .subscribe(
        response => {
          console.log(response);
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  
  GetExportedAndNonExportedDomains(Email) {
    this.emailcol = Email;
    const data = {
      table: this.master,
      emailcol: Email
    };
    this.SpinnerService.show();
    this.utilityService.GetExportedAndNonExportedDomains(data)
      .subscribe(
        response => {
          this.checkdomains = response;
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        });
  }
}
