import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import { AllstatService } from 'src/app/services/allstat.service';
import { OngageService } from '../../services/ongage.service';
import { SearchEmailService } from '../../services/search-email.service';
import { Global } from '../common/global';

@Component({
  selector: 'app-ongage-export',
  templateUrl: './ongage-export.component.html',
  styleUrls: ['./ongage-export.component.css']
})
export class OngageExportComponent implements OnInit {
  id: any;
  heading = 'Ongage Export';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  total = 0;
  success = 0;
  failed = 0;
  processed = 0;
  exportid: any;
  statid: any;
  mySub: Subscription;
  constructor(private router: Router, private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, private ongageService: OngageService) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (Global.guid != this.id) {
      this.router.navigate(['/unauthorized']);
    }
  }
  ngOnInit(): void {
    //this.exportid = 1880323250;
    this.ongageService.OngageGetExportId()
      .subscribe(
        response => {
          this.exportid = response.exportid;
        },
        error => {
        });
  }
  Search(e) {
    const data =
    {
      id: this.exportid
    };
    this.ongageService.DownloadOngageFile(data)
      .subscribe(
        response => {
          this.statid = response.statid;
          if (Number(response.totalrec) > 0) {
            this.SpinnerService.show();
            this.mySub = interval(1000).subscribe((func => {
              this.getongageStats(response.statid);
            }))
          this.PostCsvData();
          }
          
        },
        error => {
        });
  }
  getongageStats(statid) {
    const data =
    {
      id: statid
    };
    this.ongageService.getongageStats(data)
      .subscribe(
        response => {
          this.total = response[0].total;
          this.processed = response[0].process;
          this.success = response[0].success;
          this.failed = response[0].failed;
         
          if ((Number(this.processed) > 0 && Number(this.processed) == Number(this.total)) || Number(this.processed) > Number(this.total) ) {
            this.SpinnerService.hide();
            this.stopInterval();
          }
        },
        error => {
        });
  }
  stopInterval() {
    this.mySub.unsubscribe()
  }

  PostCsvData() {
    const data = {
      statid: this.statid,
    };
    this.ongageService.InsertOngage(data)
      .subscribe(
        response => {
        },
        error => {
          console.log(error);
        });
  }
  

}
